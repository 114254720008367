<div class="online-booking pt-5 pt-md-0">
  <section class="first-section container d-md-flex align-items-center text-center text-md-start">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1 class="fw-bold">{{'components.online-booking.title' | translate}}</h1>
        <p class="mt-3 mb-4 col-md-9 pe-3">{{'components.online-booking.title-description' | translate}}</p>
        <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
           gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        <div class="mt-4 text-left">
          <a class="link-underline link-underline-opacity-25"
             gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.7-days-free-trial' | translate}}</a><br/>
          <span class="text-secondary">{{'components.common.no-credit-card-required' | translate}}
            <br/>{{'components.common.cancel-anytime' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 col-lg-7 position-illust">
      <figure>
        <img alt="Image Description" class="w-70 position-illust" src="/assets/img/online-booking.jpg">
      </figure>
    </div>
  </section>

  <section class="row bg-light pb-lg-5 pb-md-5 pb-0 py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 mb-5">
          <div class="text-center">
            <h2 class="fw-bold">{{'components.online-booking.get-more-clients-title' | translate}}</h2>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-5">
          <div class="text-center">
            <div class="display-4 text-primary">25%</div>
            <p class="mb-0">{{'components.online-booking.get-more-clients-item1' | translate}}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-5">
          <p class="text-dark">{{'components.online-booking.get-more-clients-item1-description1' | translate}}
            <b>25%</b> {{'components.online-booking.get-more-clients-item1-description2' | translate}}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 mb-5">
          <div class="text-center">
            <div class="display-4 text-primary">42%</div>
            <p class="mb-0">{{'components.online-booking.get-more-clients-item2' | translate}}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-5">
          <p class="text-dark">{{'components.online-booking.get-more-clients-item2-description1' | translate}}
            <b>42%</b> {{'components.online-booking.get-more-clients-item2-description2' | translate}}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="pb-5">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="justify-content-center">
                  <h3 class="mb-3">{{'components.online-booking.online-booking-widget-title' | translate}}</h3>
                  <p>{{'components.online-booking.online-booking-widget-description1' | translate}}
                    <br/>{{'components.online-booking.online-booking-widget-description2' | translate}}
                    <br/>{{'components.online-booking.online-booking-widget-description3' | translate}}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 text-center">
                <img alt="Screenshot" class="img-fluid w-50 w-md-40 w-lg-70 w-xl-50 text-center shadow"
                     src="/assets/img/widget-{{translateService.currentLang.split('-')[0]}}.png">
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="justify-content-center">
                  <h3 class="mb-3">{{'components.online-booking.mobile-app-title' | translate}}</h3>
                  <p>{{'components.online-booking.mobile-app-description1' | translate}}
                    <br>{{'components.online-booking.mobile-app-description2' | translate}}
                    <b>60%</b> {{'components.online-booking.mobile-app-description3' | translate}}
                    <br>{{'components.online-booking.mobile-app-description4' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-6 text-center">
                <img alt="Screenshot" class="img-fluid w-50 w-md-40 w-lg-70 w-xl-50"
                     src="/assets/img/mobile-app-{{translateService.currentLang.split('-')[0]}}.jpg">
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="justify-content-center">
                  <h3 class="mb-3">{{'components.online-booking.social-networks-title' | translate}}</h3>
                  <p>{{'components.online-booking.social-networks-description1' | translate}}
                    <br>{{'components.online-booking.social-networks-description2' | translate}}
                    <br>{{'components.online-booking.social-networks-description3' | translate}}
                    <b>25%</b> {{'components.online-booking.social-networks-description4' | translate}}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 text-center">
                <img alt="Screenshot" class="img-fluid w-50 w-md-40 w-lg-70 w-xl-50 shadow"
                     src="/assets/img/social-media-{{translateService.currentLang.split('-')[0]}}.jpg">
              </div>
            </div>
          </div>
          <div *ngIf="false" class="pb-5">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="justify-content-center">
                  <h3 class="mb-3">{{'components.online-booking.messengers-title' | translate}}</h3>
                  <p>{{'components.online-booking.messengers-description1' | translate}}
                    <br>{{'components.online-booking.messengers-description2' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-6 text-center">
                <img alt="Screenshot" class="img-fluid w-50 w-md-40 w-lg-70 w-xl-50"
                     src="/assets/img/chatbot-{{translateService.currentLang.split('-')[0]}}.png">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="row align-items-center parallax-block parallax-img-4" data-overlay="5">
    <div class="container z-0">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="text-center">
            <h2
              class="fw-bold text-white mb-3">{{'components.online-booking.not-just-online-booking-title' | translate}}</h2>
            <p class="text-white">{{'components.online-booking.not-just-online-booking-description' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row px-5">
        <div class="col-lg-12">
          <div class="text-center">
            <ul class="check-list mb-0 d-inline-block text-start">
              <li>{{'components.online-booking.not-just-online-booking-item1' | translate}}</li>
              <li>{{'components.online-booking.not-just-online-booking-item2' | translate}}</li>
              <li>{{'components.online-booking.not-just-online-booking-item3' | translate}}</li>
              <li>{{'components.online-booking.not-just-online-booking-item4' | translate}}</li>
              <li>{{'components.online-booking.not-just-online-booking-item5' | translate}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-3">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="row align-items-center parallax-block parallax-img-5" data-overlay="5">
    <div class="container z-0">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="text-center">
            <h2
              class="fw-bold text-white mb-3">{{'components.online-booking.online-booking-widget2-title1' | translate}}
              <br>{{'components.online-booking.online-booking-widget2-title2' | translate}}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row px-3">
        <div class="col-lg-6">
          <ul class="p-0 check-list mb-0">
            <li>{{'components.online-booking.online-booking-widget2-item1' | translate}}</li>
            <li>{{'components.online-booking.online-booking-widget2-item2' | translate}}</li>
            <li>{{'components.online-booking.online-booking-widget2-item3' | translate}}</li>
            <li>{{'components.online-booking.online-booking-widget2-item4' | translate}}</li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="p-0 check-list">
            <li>{{'components.online-booking.online-booking-widget2-item5' | translate}}</li>
            <li>{{'components.online-booking.online-booking-widget2-item6' | translate}}</li>
            <li>{{'components.online-booking.online-booking-widget2-item7' | translate}}</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center pt-3">
          <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
             gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-for-free' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="text-center">
        <h2 class="fw-bold">{{'components.online-booking.analytics-systems-title' | translate}}</h2>
      </div>
      <div class="row justify-content-center text-center pt-5">
        <div class="col-4 mb-5 align-self-center">
          <img alt="google logo" class="px-2 img-fluid" src="/assets/logos/google-analytics.svg">
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3 class="fw-bold mb-4">{{'components.common.join-businesses1' | translate}}
            <br>{{'components.common.join-businesses2' | translate}}</h3>
          <p class="text-center mt-4">
            <a class="btn btn-warning px-4 py-2 text-uppercase fw-semibold"
               gaCategory="sign-up" gaEvent="sign-up-online-booking_click"
               href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
