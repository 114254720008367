import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: false
})
export class NotificationsComponent {
  protected readonly environment = environment;

  constructor(
    protected translateService: TranslateService,
  ) {
  }
}
