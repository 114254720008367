<div class="home pt-5 pt-md-0">
  <section class="first-section d-md-flex align-items-center">
    <div class="row align-content-center">
      <div class="col-12 col-md-9 col-lg-7 align-items-center justify-content-center">
        <h1 class="box-animate-text fw-bold pb-5">
          <ngx-typed-js [backDelay]="1000"
                        [backSpeed]="20"
                        [loop]="true"
                        [shuffle]="true"
                        [smartBackspace]="true"
                        [strings]="businesses"
                        [typeSpeed]="50"
          >
            <span>{{'components.home.title' | translate}} </span><span class="typing text-success"></span>
          </ngx-typed-js>
        </h1>
        <p class="my-5 my-lg-4 me-md-3 text-left">{{'components.home.title-description' | translate}}</p>
        <a class="w-100 w-sm-50 btn btn-red py-2 text-uppercase fw-semibold"
           gaCategory="sign-up" gaEvent="sign-up-home_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
        <div class="mt-4 text-left">
          <a class="link-underline link-underline-opacity-25"
             gaCategory="sign-up" gaEvent="sign-up-home_click"
             href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.7-days-free-trial' | translate}}</a><br/>
          <span class="text-secondary">{{'components.common.no-credit-card-required' | translate}}
            <br/>{{'components.common.free-system-setup' | translate}}</span>
        </div>
      </div>
      <div class="col-12 col-md-3 col-lg-5 position-illust">
        <figure>
          <img alt="Image Description" class="hero-img position-illust pb-md-5"
               src="/assets/img/schedule-1-2-{{translateService.currentLang.split('-')[0]}}.png"/>
        </figure>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="row justify-content-center">
      <div class="col-11 pb-5 text-center">
        <h2 class="fw-bold mb-2">{{'components.home.lets-free-up-your-time' | translate}}</h2>
        <p class="m-0">{{'components.home.lets-free-up-your-time-description' | translate}}</p>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-7 text-center">
        <h2 class="fw-bold mb-2">{{'components.home.manage-your-business-title' | translate}}<br
          class="d-none d-sm-block"/> {{'components.home.manage-your-business-title2' | translate}}
        </h2>
        <p class="m-0">{{'components.home.manage-your-business-description' | translate}}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-11 col-md-6 col-lg-4 row">
        <div class="px-4 pt-5">
          <div class="btn btn-red p-2 cursor-default">
            <bi class="icon-medium" name="calendar-check"></bi>
          </div>
          <div class="pt-4">
            <h3 class="h5">{{'components.home.online-booking-and-payments' | translate}}</h3>
          </div>
          <p>{{'components.home.online-booking-and-payments-description' | translate}}</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-4 row">
        <div class="px-4 pt-5">
          <div class="btn btn-red p-2 cursor-default">
            <bi class="icon-medium" name="calendar-event"></bi>
          </div>
          <div class="pt-4">
            <h3 class="h5">{{'components.home.appointment-scheduling' | translate}}</h3>
          </div>
          <p>{{'components.home.appointment-scheduling-description' | translate}}</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-4 row">
        <div class="px-4 pt-5">
          <div class="btn btn-red p-2 cursor-default">
            <bi class="icon-medium" name="person-rolodex"></bi>
          </div>
          <div class="pt-4">
            <h3 class="h5">{{'components.home.team-management' | translate}}</h3>
          </div>
          <p>{{'components.home.team-management-description' | translate}}</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-4 row">
        <div class="px-4 pt-5">
          <div class="btn btn-red p-2 cursor-default">
            <bi class="icon-medium" name="archive"></bi>
          </div>
          <div class="pt-4">
            <h3 class="h5">{{'components.home.client-database' | translate}}</h3>
          </div>
          <p>{{'components.home.client-database-description' | translate}}</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-4 row">
        <div class="px-4 pt-5">
          <div class="btn btn-red p-2 cursor-default">
            <bi class="icon-medium" name="chat"></bi>
          </div>
          <div class="pt-4">
            <h3 class="h5">{{'components.home.communication-with-clients-and-team' | translate}}</h3>
          </div>
          <p>{{'components.home.communication-with-clients-and-team-description' | translate}}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 order-lg-2">
          <img alt="Online Booking Software and Appointment Scheduling" class="col-md-8 col-lg-12"
               src="/assets/img/iphones-2-{{translateService.currentLang.split('-')[0]}}.png">
        </div>
        <div class="col-lg-7">
          <div class="col-12">
            <h3 class="text-red fw-bold">{{'components.home.online-booking-title' | translate}}</h3>
            <h2 class="fw-bold">{{'components.home.online-booking-title2' | translate}}<br
              class="d-none d-lg-block"/> {{'components.home.online-booking-title3' | translate}}</h2>
          </div>
          <div class="col col-lg-12">
            <p>{{'components.home.online-booking-description1' | translate}}</p>
          </div>
          <div class="col-12 d-flex align-items-center pt-3">
            <div class="btn btn-success p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.online-booking-item1' | translate}}</p>
          </div>
          <div class="col-12 d-flex align-items-center pt-3">
            <div class="btn btn-success p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.online-booking-item2' | translate}}</p>
          </div>
          <div class="col-12 d-flex align-items-center pt-3">
            <div class="btn btn-success p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.online-booking-item3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 d-flex flex-column justify-content-center order-lg-0">
          <img alt="Online Booking Software and Appointment Scheduling" class="position-lg-absolute"
               src="/assets/img/iphone-hair-salon-{{translateService.currentLang.split('-')[0]}}.jpg">
        </div>
        <div class="col-12 col-lg-7 order-lg-1">
          <div class="col-12">
            <h3 class="fw-bold text-success">{{'components.home.scheduling-software-title' | translate}}</h3>
            <h2 class="fw-bold">{{'components.home.scheduling-software-title2' | translate}}<br
              class="d-none d-md-block"/> {{'components.home.scheduling-software-title3' | translate}}
            </h2>
          </div>
          <div class="order-md-3 col col-lg-11">
            <p>{{'components.home.scheduling-software-description' | translate}}</p>
          </div>
          <div class="col-lg-7 d-flex align-items-center pt-3">
            <div class="btn btn-red p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.scheduling-software-item1' | translate}}</p>
          </div>
          <div class="col-lg-7 d-flex align-items-center pt-3">
            <div class="btn btn-red p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.scheduling-software-item2' | translate}}</p>
          </div>
          <div class="col-lg-7 d-flex align-items-center pt-3">
            <div class="btn btn-red p-2 me-3 cursor-default">
              <bi class="icon-medium" name="check"></bi>
            </div>
            <p class="col-8 col-md-10 mb-0">{{'components.home.scheduling-software-item3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 order-lg-2">
          <img alt="Online Booking Software and Appointment Scheduling" class="col-md-8 col-lg-12"
               src="/assets/img/push-notification-{{translateService.currentLang.split('-')[0]}}.jpg">
        </div>
        <div class="col-lg-7 order-lg-1">
          <h3 class="text-red fw-bold">{{'components.home.communication-with-clients-title' | translate}}</h3>
          <h2 class="fw-bold">{{'components.home.communication-with-clients-title2' | translate}}</h2>
        </div>
        <div class="order-md-3 col col-lg-10">
          <p>{{'components.home.communication-with-clients-description' | translate}}</p>
        </div>
        <div class="col-12 order-md-4 d-flex pt-3 align-items-center">
          <div class="btn btn-success p-2 me-3 cursor-default">
            <bi class="icon-medium" name="check"></bi>
          </div>
          <p class="col-8 col-md-10 mb-0">{{'components.home.communication-with-clients-item1' | translate}}</p>
        </div>
        <div class="col-12 order-md-4 d-flex pt-3 align-items-center">
          <div class="btn btn-success p-2 me-3 cursor-default">
            <bi class="icon-medium" name="check"></bi>
          </div>
          <p class="col-8 col-md-10 mb-0">{{'components.home.communication-with-clients-item3' | translate}}</p>
        </div>
        <div class="col-12 order-md-4 d-flex pt-3 align-items-center">
          <div class="btn btn-success p-2 me-3 cursor-default">
            <bi class="icon-medium" name="check"></bi>
          </div>
          <p class="col-8 col-md-10 mb-0">{{'components.home.communication-with-clients-item20' | translate}} <span
            class="text-primary">{{'components.home.communication-with-clients-item21' | translate}}</span> {{'components.home.communication-with-clients-item22' | translate}}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5 bg-light row">
    <div class="pb-5">
      <div class="col-12 text-center">
        <h2 class="fw-bold">Agenda<span
          class="text-success">cita</span> {{'components.home.appointment-based-businesses-title' | translate}}
        </h2>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center px-5">
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <img src="/assets/icons/self_care.svg"/>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business1' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <mat-icon class="icon-medium">face_4</mat-icon>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business2' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <bi class="icon-medium" name="scissors"></bi>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business3' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <img src="/assets/icons/nail.svg"/>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business4' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <img src="/assets/icons/lash.svg"/>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business5' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <img src="/assets/icons/wax.svg"/>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business6' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <img src="/assets/icons/massage.svg"/>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business7' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <mat-icon class="icon-medium">spa</mat-icon>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business8' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <mat-icon class="icon-medium">gesture</mat-icon>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business9' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-4 col-xl-3 px-1">
        <div class="box-img mb-2 px-2 px-sm-3 py-2">
          <div class="col-6">
            <div class="btn btn-warning p-2 my-2 cursor-default">
              <bi class="icon-medium" name="three-dots"></bi>
            </div>
          </div>
          <div class="col-12">
            <h3 class="h5 fw-bold">{{'components.home.appointment-based-business10' | translate}}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5">
    <div class="container">
      <div class="text-center mx-md-auto mb-4">
        <div class="lead">
          <h2 class="fw-bold mb-0">{{'components.home.clear-pricing-title1' | translate}}
            <br/>{{'components.home.clear-pricing-title2' | translate}}</h2>
          <small>{{'components.home.clear-pricing-text' | translate}} <br class="d-lg-none"/>
            <span
              class="text-primary">{{'components.home.clear-pricing-from' | translate}} {{individualPriceTier[translateService.currentLang] | currency:getLocaleCurrencyCode():'symbol':'1.0-2':translateService.currentLang}}</span>
          </small>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <a class="btn btn-red fw-semibold px-4 py-2 text-uppercase"
           gaCategory="sign-up" gaEvent="sign-up-home_click"
           href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.start-free-trial' | translate}}</a>
      </div>
    </div>
  </section>
</div>
