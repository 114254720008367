export const environment = {
  production: false,
  domainUrl: 'https://dev.agendacita.mx',
  apiUrl: 'https://api-dev.agendacita.mx',
  appUrl: 'https://app-dev.agendacita.mx',
  gaMeasurementId: 'G-7JNR1REENY', // dev.agendacita.mx Data Stream
  cognito: {
    userPoolId: 'us-east-1_nihQigPCC',
    userPoolWebClientId: '34lmt3s0uip5iluuub8ctf9i1m',
    email: 'guest@agendacita.mx',
    password: 'gajfEr-wybse1-rodsex',
  },
};
