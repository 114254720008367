import {RouterStateSnapshot, TitleStrategy} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Injectable} from "@angular/core";

import {TranslateService} from "@ngx-translate/core";

import {LocaleService} from "../services/locale.service";
import {LOCALIZE_CACHE_NAME} from "../app-routing.module";
import {defaultLocale} from "../locales";

@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly localeService: LocaleService,
    private readonly title: Title,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const defaultTitle = 'Agendacita';
    const title = this.buildTitle(snapshot);
    if (title) {
      let translatedTitle = this.translateService.instant(title);
      if (!translatedTitle) {
        translatedTitle = defaultTitle + ' | ' + this.localeService.getLocaleCountry(localStorage.getItem(LOCALIZE_CACHE_NAME) || defaultLocale);
      }
      this.title.setTitle(translatedTitle);
    } else {
      this.title.setTitle(defaultTitle + ' | ' + this.localeService.getLocaleCountry(localStorage.getItem(LOCALIZE_CACHE_NAME) || defaultLocale));
    }
  }
}
