import {Component, Inject, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from "@angular/common";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

import {environment} from '../environments/environment';
import {locales} from "./locales";
import {AbstractSubscriber} from "./services/abstract-subscriber";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class AppComponent extends AbstractSubscriber {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly renderer: Renderer2,
    protected translateService: TranslateService,
  ) {
    super();

    this.addHeadElements();

    document.body.className = this.translateService.currentLang;
    this.track(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        document.body.className = event.lang;
      })
    );
  }

  private addHeadElements() {
    const linkCanonical = this.renderer.createElement('link');
    this.renderer.setAttribute(linkCanonical, 'rel', 'canonical');
    this.renderer.setAttribute(linkCanonical, 'hreflang', this.translateService.currentLang);
    this.renderer.setAttribute(linkCanonical, 'href', environment.domainUrl + '/' + this.translateService.currentLang);
    this.renderer.appendChild(this.document.head, linkCanonical);

    const alternateLanguages = locales.filter((lang) => {
      return lang !== this.translateService.currentLang;
    });

    alternateLanguages.forEach((alternateLanguage) => {
      const linkAlternate = this.renderer.createElement('link');
      this.renderer.setAttribute(linkAlternate, 'rel', 'alternate');
      this.renderer.setAttribute(linkAlternate, 'hreflang', alternateLanguage);
      this.renderer.setAttribute(linkAlternate, 'href', environment.domainUrl + '/' + alternateLanguage);
      this.renderer.appendChild(this.document.head, linkAlternate);
    });

    const linkCountrySelector = this.renderer.createElement('link');
    this.renderer.setAttribute(linkCountrySelector, 'rel', 'alternate');
    this.renderer.setAttribute(linkCountrySelector, 'hreflang', 'x-default');
    this.renderer.setAttribute(linkCountrySelector, 'href', environment.domainUrl + '/choose-country-region');
    this.renderer.appendChild(this.document.head, linkCountrySelector);

    if (!environment.production) {
      const metaRobots = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaRobots, 'name', 'robots');
      this.renderer.setAttribute(metaRobots, 'content', 'noindex,nofollow,noarchive');
      this.renderer.appendChild(this.document.head, metaRobots);
    }
  }
}
