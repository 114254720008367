import * as i0 from '@angular/core';
import { Component, Inject, Input, NgModule, Optional } from '@angular/core';
const _c0 = ["*"];
class Icons {
  constructor(icons) {
    this.icons = icons;
  }
}
function uppercamelcase(str) {
  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, firstLetter => {
    return firstLetter.toUpperCase();
  }).replace(/[-_]/g, '');
}
class BootstrapIcons {
  constructor(elem, changeDetector, icons) {
    this.elem = elem;
    this.changeDetector = changeDetector;
    this.icons = icons;
  }
  ngOnChanges(changes) {
    // icons are provided as an array of objects because of "multi: true"
    const icons = Object.assign({}, ...this.icons);
    const svg = icons[uppercamelcase(changes.name.currentValue)] || "";
    if (!svg) {
      console.warn(`Icon not found: ${changes.name.currentValue}\n` + `Refer to documentation on https://github.com/biutas/ng-bootstrap-icons/issues`);
    }
    this.elem.nativeElement.innerHTML = svg;
    this.changeDetector.markForCheck();
  }
}
BootstrapIcons.ɵfac = function BootstrapIcons_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BootstrapIcons)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(Icons));
};
BootstrapIcons.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BootstrapIcons,
  selectors: [["bt-icon"], ["bootstrap-icon"], ["bi"]],
  inputs: {
    name: "name"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function BootstrapIcons_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{display:flex;justify-content:center;align-items:center;fill:none;stroke-linecap:round;stroke-linejoin:round}[_nghost-%COMP%]   svg[_ngcontent-%COMP%]{width:100%!important;height:100%!important}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BootstrapIcons, [{
    type: Component,
    args: [{
      selector: "bt-icon, bootstrap-icon, bi",
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:flex;justify-content:center;align-items:center;fill:none;stroke-linecap:round;stroke-linejoin:round}:host svg{width:100%!important;height:100%!important}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: Icons,
      decorators: [{
        type: Inject,
        args: [Icons]
      }]
    }];
  }, {
    name: [{
      type: Input
    }]
  });
})();
class BootstrapIconsModule {
  constructor(icons) {
    this.icons = icons;
    if (!this.icons) {
      throw new Error(`No icon provided. Make sure to use 'BootstrapIconsModule.pick({ ... })' when importing the module\n` + `Refer to documentation on https://github.com/biutas/ng-bootstrap-icons/issues`);
    }
  }
  static pick(icons) {
    return {
      ngModule: BootstrapIconsModule,
      providers: [{
        provide: Icons,
        multi: true,
        useValue: icons
      }]
    };
  }
}
BootstrapIconsModule.ɵfac = function BootstrapIconsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BootstrapIconsModule)(i0.ɵɵinject(Icons, 8));
};
BootstrapIconsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BootstrapIconsModule
});
BootstrapIconsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BootstrapIconsModule, [{
    type: NgModule,
    args: [{
      declarations: [BootstrapIcons],
      exports: [BootstrapIcons]
    }]
  }], function () {
    return [{
      type: Icons,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BootstrapIcons, BootstrapIconsModule };
