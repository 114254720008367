export const LOCALE_ES_MX = 'es-mx';
export const LOCALE_EN_US = 'en-us';
export const LOCALE_ES_US = 'es-us';
export const LOCALE_ES_ES = 'es-es';
export const LOCALE_EN_150 = 'en-150';
export const LOCALE_EN_001 = 'en-001';

export const locales = [LOCALE_EN_US, LOCALE_ES_US, LOCALE_ES_MX, LOCALE_ES_ES, LOCALE_EN_150, LOCALE_EN_001];

export const languages = ['es', 'en'];

// get supported locales by country here https://localizely.com/country-code/MX/

export const countryDefaultLocale: { [country: string]: string } = {
  mx: LOCALE_ES_MX,
  us: LOCALE_EN_US,
  es: LOCALE_ES_ES,
  xe: LOCALE_EN_150,
}

export const countryDialCode: { [country: string]: string } = {
  mx: '+52',
  us: '+1',
  es: '+34',
  '150': '+30',
  '001': '+1',
};

export const defaultLocale = LOCALE_EN_001;
