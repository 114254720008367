import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {retry} from "rxjs";

import {countryCurrency, LOCALE_EN_001, LOCALE_EN_150, LOCALE_ES_ES, LOCALE_ES_MX, LOCALE_ES_US} from "../locales";
import {AbstractSubscriber} from "../services/abstract-subscriber";
import {environment} from "../../environments/environment";

const PRICING_MODE_MONTHLY = 'monthly';
const PRICING_MODE_YEARLY = 'yearly';

export type PriceTier = {
  base: number,
  increment?: number,
  whatsapp?: number,
  sms?: number,
  minProfessionals?: number,
  unlimited?: number,
  whatsappUnlimited?: number,
  smsUnlimited?: number,
};

export type PriceTiers = {
  individualPriceTier: { [currency: string]: PriceTier },
  standardPriceTier: { [currency: string]: PriceTier },
  premiumPriceTier: { [currency: string]: PriceTier },
};

@Component({
  selector: 'app-pricing-detail',
  templateUrl: './pricing-detail.component.html',
  styleUrls: ['./pricing-detail.component.scss'],
  standalone: false
})
export class PricingDetailComponent extends AbstractSubscriber implements OnInit {
  protected readonly Array = Array;

  protected loading = true;

  protected businessTypeId = 9;
  protected businessTypeName = '';

  protected pricingMode: typeof PRICING_MODE_MONTHLY | typeof PRICING_MODE_YEARLY = PRICING_MODE_MONTHLY;

  protected yearlyDiscount = 1; // months

  protected individualPriceTier: { [currency: string]: PriceTier } = {};
  protected standardPriceTier: { [currency: string]: PriceTier } = {};
  protected premiumPriceTier: { [currency: string]: PriceTier } = {};
  protected premiumPlusPriceTier: { [currency: string]: PriceTier } = {};

  protected standardWhatsApp = true;
  protected premiumWhatsApp = true;
  protected premiumSMS = true;

  protected standardPriceTierProfessionalsCount = 0;
  protected premiumPriceTierProfessionalsCount = 0;
  protected premiumPlusPriceTierProfessionalsCount = 0;

  protected adjectiveFirst = true;

  protected readonly PRICING_MODE_MONTHLY = PRICING_MODE_MONTHLY;
  protected readonly PRICING_MODE_YEARLY = PRICING_MODE_YEARLY;
  protected readonly Infinity = Infinity;
  protected readonly environment = environment;
  protected readonly countryCurrency = countryCurrency;
  protected readonly LOCALE_EN_001 = LOCALE_EN_001;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly route: ActivatedRoute,
    protected translateService: TranslateService,
  ) {
    super();

    this.fillPriceValues();
    this.loadPriceTiers();

    this.track(
      this.translateService.onLangChange.subscribe((_: LangChangeEvent) => {
        this.fillPriceValues();
      })
    );
  }

  ngOnInit() {
    this.track(
      this.route.params.subscribe((params) => {
        this.businessTypeId = +params['businessTypeId']; // (+) converts string 'id' to a number

        this.fillPriceValues();
      })
    );
  }

  protected onMonthlyClick() {
    this.pricingMode = PRICING_MODE_MONTHLY;
  }

  protected onYearlyClick() {
    this.pricingMode = PRICING_MODE_YEARLY;
  }

  protected getLocaleCurrencyCode(): string {
    return countryCurrency[this.translateService.currentLang.split('-')[1]];
  }

  protected annualMultiplier() {
    return this.pricingMode === PRICING_MODE_YEARLY ? 12 - this.yearlyDiscount : 1;
  }

  protected getPriceTierValue(priceTier: PriceTier, professionalsCount: number, whatsapp = false, sms = false): number {
    if (professionalsCount <= priceTier.minProfessionals!) {
      const whatsAppAddition = whatsapp ? (priceTier.whatsapp! * priceTier.minProfessionals!) : 0;
      const smsAddition = sms ? ((priceTier.sms || 0) * priceTier.minProfessionals!) : 0;
      return priceTier.base + whatsAppAddition + smsAddition;
    }

    if (professionalsCount === Infinity) {
      const whatsAppAddition = whatsapp ? (priceTier.whatsappUnlimited || 0) : 0;
      const smsAddition = sms ? (priceTier.smsUnlimited || 0) : 0;
      return priceTier.unlimited as number + whatsAppAddition + smsAddition;
    }

    const whatsAppAddition = whatsapp ? (priceTier.whatsapp! * professionalsCount) : 0;
    const smsAddition = sms ? ((priceTier.sms || 0) * professionalsCount) : 0;
    return priceTier.base + (professionalsCount - priceTier.minProfessionals!) * priceTier.increment! + whatsAppAddition + smsAddition;
  }

  private fillPriceValues() {
    if (this.businessTypeId !== 9) {
      this.businessTypeName = this.translateService.instant(`components.pricing.business-type-${this.businessTypeId}.title`);
    } else {
      this.businessTypeName = 'Other';
    }

    this.standardPriceTierProfessionalsCount = 2;
    this.premiumPriceTierProfessionalsCount = 5;
    this.premiumPlusPriceTierProfessionalsCount = 15;

    this.standardWhatsApp = true;
    this.premiumWhatsApp = true;
    this.premiumSMS = false;

    if (
      this.translateService.currentLang === LOCALE_ES_ES ||
      this.translateService.currentLang === LOCALE_EN_150 ||
      this.translateService.currentLang === LOCALE_EN_001
    ) {
      this.standardWhatsApp = false;
      this.premiumWhatsApp = false;
      this.premiumSMS = false;
    }

    this.adjectiveFirst = (this.translateService.currentLang !== LOCALE_ES_MX && this.translateService.currentLang !== LOCALE_ES_US && this.translateService.currentLang !== LOCALE_ES_ES) || this.businessTypeId === 9;
  }

  private loadPriceTiers() {
    this.httpClient.get<PriceTiers>(environment.apiUrl + '/public/priceTiers').pipe(retry(1)).subscribe({
      next: (priceTiers: PriceTiers) => {
        this.individualPriceTier = priceTiers.individualPriceTier;
        this.standardPriceTier = priceTiers.standardPriceTier;
        this.premiumPriceTier = priceTiers.premiumPriceTier;
        this.premiumPlusPriceTier = priceTiers.premiumPriceTier; // TODO fix this on tier launch

        this.loading = false;
      },
    });
  }
}
