<div class="pricing-detail py-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="row text-left text-lg-center">
        <h1 class="h2 fw-bold col-12 px-0">{{'components.pricing-detail.subscription-plans-for' | translate}}
          <span
            *ngIf="adjectiveFirst">{{businessTypeName}}
            {{'components.pricing-detail.businesses' | translate}}</span><span
            *ngIf="!adjectiveFirst">{{'components.pricing-detail.businesses' | translate}} {{businessTypeName}}</span>
        </h1>
      </div>
      <div class="col-md-3 d-lg-none"></div>
    </div>

    <div class="row pt-4">
      <div class="fw-medium">
        <div class="d-inline-flex align-items-center cursor-pointer"
             routerLink="/{{translateService.currentLang}}/pricing">
          <mat-icon>chevron_left</mat-icon>
          <div class="ps-1">{{'components.pricing-detail.change-business-type' | translate}}</div>
        </div>
      </div>
    </div>

    <p class="mt-5 text-center">
      <mark
        class="px-2 rounded-3">{{'components.pricing-detail.pay-annually-and-get' | translate}} {{yearlyDiscount}} {{yearlyDiscount > 1 ? ('components.pricing-detail.months' | translate) : ('components.pricing-detail.month' | translate)}}
        {{'components.pricing-detail.free' | translate}}
      </mark>
    </p>

    <div class="d-flex flex-wrap align-items-center justify-content-center mt-2">
      <ul class="nav-pills rounded-pill bg-light p-1 cursor-pointer" ngbNav>
        <li ngbNavItem>
          <button (click)="onMonthlyClick()" class="rounded-pill"
                  ngbNavLink>{{'components.pricing-detail.monthly' | translate}}
          </button>
        </li>
        <li ngbNavItem>
          <button (click)="onYearlyClick()" class="rounded-pill"
                  ngbNavLink>{{'components.pricing-detail.yearly' | translate}}
          </button>
        </li>
      </ul>
    </div>

    <div class="pricing-wrapper mb-14 mt-6 mb-md-16 ">
      <div class="row gy-6 mt-3 mt-md-5">

        <!--Individual-->
        <div class="col-sm-6 col-md-4  pb-4">
          <div class="card border-0">
            <div class="card-body">
              <h4 class="card-title">{{'components.pricing-detail.individual.title' | translate}}</h4>
              <p>{{'components.pricing-detail.individual.description' | translate}}</p>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-from' | translate}}:<span
                class="fs-5-6"></span></span>
              <s *ngIf="pricingMode === PRICING_MODE_YEARLY"
                 class="fw-semibold fs-5-6 text-secondary ps-2">{{individualPriceTier[translateService.currentLang] * 12 | customCurrency:getLocaleCurrencyCode():'':'1.0-2':translateService.currentLang | removeThousandsSeparator}}</s>
              <div
                class="fw-semibold fs-1-2 text-dark">{{individualPriceTier[translateService.currentLang] * annualMultiplier() | customCurrency:getLocaleCurrencyCode():'symbol':'1.0-2':translateService.currentLang | removeThousandsSeparator}}
              </div>
              <span class="fw-semibold text-dark"><span
                *ngIf="pricingMode === PRICING_MODE_MONTHLY">{{'components.pricing-detail.month' | translate}}</span><span
                *ngIf="pricingMode === PRICING_MODE_YEARLY">{{'components.pricing-detail.year' | translate}}</span> / {{getLocaleCurrencyCode()}}</span>
              <div class="mt-2">
                <select class="form-select" disabled="">
                  <option selected=""
                          value="{{individualPriceTier[translateService.currentLang] * annualMultiplier()}}">
                    1 {{'components.pricing-detail.professional' | translate}}
                  </option>
                </select>
              </div>
              <a
                class="btn btn-red my-4 px-4 py-2 fw-semibold w-100"
                gaCategory="sign-up"
                gaEvent="sign-up-pricing-detail-{{businessTypeId}}-individual(1pro)-{{pricingMode}}-{{individualPriceTier[translateService.currentLang] * annualMultiplier()}}{{getLocaleCurrencyCode()}}_click"
                href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-it-for-free' | translate}}</a>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-includes' | translate}}</span>
              <ul class="check-list mt-2 mb-0">
                <li><span>{{'components.pricing-detail.individual.includes-1' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.individual.includes-2' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.individual.includes-6' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.individual.includes-3' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.individual.includes-4' | translate}}</span></li>
                <li><span><strong>{{'components.pricing-detail.individual.includes-5' | translate}}</strong></span></li>
              </ul>
            </div>
          </div>
        </div>

        <!--Standard-->
        <div class="col-sm-6 col-md-4  pb-4">
          <div class="card border-0">
            <div class="card-body">
              <h4 class="card-title">{{'components.pricing-detail.standard.title' | translate}}</h4>
              <p>{{'components.pricing-detail.standard.description' | translate}}</p>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-from' | translate}}:<span
                class="fs-5-6"></span></span>
              <s *ngIf="pricingMode === PRICING_MODE_YEARLY"
                 class="fw-semibold fs-5-6 text-secondary ps-2">{{getPriceTierValue(standardPriceTier[translateService.currentLang], standardPriceTierProfessionalsCount, standardWhatsApp) * 12 | customCurrency:getLocaleCurrencyCode():'':'1.0-2':translateService.currentLang | removeThousandsSeparator}}</s>
              <div
                class="fw-semibold fs-1-2 text-dark">{{getPriceTierValue(standardPriceTier[translateService.currentLang], standardPriceTierProfessionalsCount, standardWhatsApp) * annualMultiplier() | customCurrency:getLocaleCurrencyCode():'symbol':'1.0-2':translateService.currentLang | removeThousandsSeparator}}
              </div>
              <span class="fw-semibold text-dark"><span
                *ngIf="pricingMode === PRICING_MODE_MONTHLY">{{'components.pricing-detail.month' | translate}}</span><span
                *ngIf="pricingMode === PRICING_MODE_YEARLY">{{'components.pricing-detail.year' | translate}}</span> / {{getLocaleCurrencyCode()}}</span>
              <div class="mt-2">
                <select [(ngModel)]="standardPriceTierProfessionalsCount" class="form-select">
                  <option [ngValue]="2">
                    2 {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option *ngFor="let _ of Array(18).fill(0); let i = index"
                          [ngValue]="i + 3">{{i + 3}}
                    {{'components.pricing-detail.professionals' | translate}}
                  </option>
                </select>
              </div>
              <a
                class="btn btn-red my-4 px-4 py-2 fw-semibold w-100"
                gaCategory="sign-up"
                gaEvent="sign-up-pricing-detail-{{businessTypeId}}-standard({{standardPriceTierProfessionalsCount}}pros)-{{pricingMode}}-{{getPriceTierValue(standardPriceTier[translateService.currentLang], standardPriceTierProfessionalsCount, standardWhatsApp) * annualMultiplier()}}{{getLocaleCurrencyCode()}}_click"
                href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-it-for-free' | translate}}</a>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-includes' | translate}}</span>
              <ul class="check-list mt-2 mb-0">
                <li class="arrow-left mb-3"><span
                  class="fw-semibold text-dark">{{'components.pricing-detail.all-the-features-of' | translate}} {{'components.pricing-detail.individual.title' | translate | lowercase}}{{','}} {{'components.pricing-detail.plus' | translate}}{{':'}}</span>
                </li>
                <li><span>{{'components.pricing-detail.standard.includes-1' | translate}}</span></li>
                <li><span class="me-1">{{'components.pricing-detail.standard.includes-3' | translate}}</span><span
                  class="badge rounded-pill fs-smaller text-bg-warning">{{'components.common.coming-soon' | translate}}</span>
                </li>
                <li class="no-arrow"><label class="form-check-label cursor-pointer"><input
                  [(ngModel)]="standardWhatsApp"
                  class="form-check-input"
                  type="checkbox"><strong
                  class="cursor-pointer">{{'components.pricing-detail.standard.includes-2' | translate}}</strong></label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!--Premium-->
        <div class="col-sm-6 col-md-4  pb-4">
          <div class="card border-0 bg-warning bg-opacity-10">
            <div class="card-body">
              <h4 class="card-title">{{'components.pricing-detail.premium.title' | translate}}</h4>
              <p>{{'components.pricing-detail.premium.description' | translate}}</p>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-from' | translate}}:<span
                class="fs-5-6"></span></span>
              <s *ngIf="pricingMode === PRICING_MODE_YEARLY"
                 class="fw-semibold fs-5-6 text-secondary ps-2">{{getPriceTierValue(premiumPriceTier[translateService.currentLang], premiumPriceTierProfessionalsCount, premiumWhatsApp, premiumSMS) * 12 | customCurrency:getLocaleCurrencyCode():'':'1.0-2':translateService.currentLang | removeThousandsSeparator}}</s>
              <div
                class="fw-semibold fs-1-2 text-dark">{{getPriceTierValue(premiumPriceTier[translateService.currentLang], premiumPriceTierProfessionalsCount, premiumWhatsApp, premiumSMS) * annualMultiplier() | customCurrency:getLocaleCurrencyCode():'symbol':'1.0-2':translateService.currentLang | removeThousandsSeparator}}
              </div>
              <span class="fw-semibold text-dark"><span
                *ngIf="pricingMode === PRICING_MODE_MONTHLY">{{'components.pricing-detail.month' | translate}}</span><span
                *ngIf="pricingMode === PRICING_MODE_YEARLY">{{'components.pricing-detail.year' | translate}}</span> / {{getLocaleCurrencyCode()}}</span>
              <div class="mt-2">
                <select [(ngModel)]="premiumPriceTierProfessionalsCount" class="form-select">
                  <option *ngFor="let _ of Array(3).fill(0); let i = index"
                          [ngValue]="i + 2">{{i + 2}} {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option [ngValue]="5">
                    5 {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option *ngFor="let _ of Array(15).fill(0); let i = index"
                          [ngValue]="i + 6">{{i + 6}}
                    {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option
                    [ngValue]="Infinity">{{'components.pricing-detail.no-limits' | translate}}
                  </option>
                </select>
              </div>
              <a
                class="btn btn-red disabled my-4 px-4 py-2 fw-semibold w-100"
                gaCategory="sign-up"
                gaEvent="sign-up-pricing-detail-{{businessTypeId}}-premium({{premiumPriceTierProfessionalsCount}}pros)-{{pricingMode}}-{{getPriceTierValue(premiumPriceTier[translateService.currentLang], premiumPriceTierProfessionalsCount, premiumWhatsApp, premiumSMS) * annualMultiplier()}}{{getLocaleCurrencyCode()}}_click"
                href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.coming-soon' | translate}}</a>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-includes' | translate}}</span>
              <ul class="check-list mt-2 mb-0">
                <li class="arrow-left mb-3"><span
                  class="fw-semibold text-dark">{{'components.pricing-detail.all-the-features-of' | translate}} {{'components.pricing-detail.standard.title' | translate | lowercase}}{{','}} {{'components.pricing-detail.plus' | translate}}{{':'}}</span>
                </li>
                <li><span><strong>{{'components.pricing-detail.premium.includes-1' | translate}}</strong></span></li>
                <li><span>{{'components.pricing-detail.premium.includes-2' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.premium.includes-3' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.premium.includes-6' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.premium.includes-8' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.standard.includes-4' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.premium.includes-4' | translate}}</span></li>
                <li><span>{{'components.pricing-detail.premium.includes-5' | translate}}</span></li>
                <li class="no-arrow"><label class="form-check-label cursor-pointer"><input [(ngModel)]="premiumWhatsApp"
                                                                                           class="form-check-input"
                                                                                           type="checkbox"><strong
                  class="cursor-pointer">{{'components.pricing-detail.standard.includes-2' | translate}}</strong></label>
                </li>
                <li class="no-arrow"><label class="form-check-label cursor-pointer"><input [(ngModel)]="premiumSMS"
                                                                                           class="form-check-input"
                                                                                           type="checkbox"><strong
                  class="cursor-pointer">{{'components.pricing-detail.premium.includes-7' | translate}}</strong></label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!--TODO add later. Copy css classes from here to other tiers-->
        <!--Premium Plus-->
        <div *ngIf="false" class="col-sm-6 col-md-4 col-xl-3 pb-4">
          <div class="card border-0">
            <div class="card-body">
              <h4 class="card-title">{{'components.pricing-detail.premium-plus.title' | translate}}</h4>
              <p>{{'components.pricing-detail.premium-plus.description' | translate}}</p>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-from' | translate}}:<span
                class="fs-5-6"></span></span>
              <s *ngIf="pricingMode === PRICING_MODE_YEARLY"
                 class="fw-semibold fs-5-6 text-secondary ps-2">{{getPriceTierValue(premiumPlusPriceTier[translateService.currentLang], premiumPlusPriceTierProfessionalsCount) * 12 | customCurrency:getLocaleCurrencyCode():'':'1.0-2':translateService.currentLang | removeThousandsSeparator}}</s>
              <div
                class="fw-semibold fs-1-2 text-dark">{{getPriceTierValue(premiumPlusPriceTier[translateService.currentLang], premiumPlusPriceTierProfessionalsCount) * annualMultiplier() | customCurrency:getLocaleCurrencyCode():'symbol':'1.0-2':translateService.currentLang | removeThousandsSeparator}}
              </div>
              <span class="fw-semibold text-dark"><span
                *ngIf="pricingMode === PRICING_MODE_MONTHLY">{{'components.pricing-detail.month' | translate}}</span><span
                *ngIf="pricingMode === PRICING_MODE_YEARLY">{{'components.pricing-detail.year' | translate}}</span> / {{getLocaleCurrencyCode()}}</span>
              <div class="mt-2">
                <select [(ngModel)]="premiumPlusPriceTierProfessionalsCount" class="form-select">
                  <option *ngFor="let _ of Array(13).fill(0); let i = index"
                          [ngValue]="i + 2">{{i + 2}} {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option [ngValue]="15">
                    15 {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option *ngFor="let _ of Array(5).fill(0); let i = index"
                          [ngValue]="i + 16">{{i + 16}}
                    {{'components.pricing-detail.professionals' | translate}}
                  </option>
                  <option
                    [ngValue]="Infinity">{{'components.pricing-detail.no-limits' | translate}}
                  </option>
                </select>
              </div>
              <a
                class="btn btn-red my-4 px-4 py-2 fw-semibold w-100"
                gaCategory="sign-up"
                gaEvent="sign-up-pricing-detail-{{businessTypeId}}-premium-plus({{premiumPlusPriceTierProfessionalsCount}}pros)-{{pricingMode}}-{{getPriceTierValue(premiumPlusPriceTier[translateService.currentLang], premiumPlusPriceTierProfessionalsCount) * annualMultiplier()}}{{getLocaleCurrencyCode()}}_click"
                href="{{environment.appUrl}}/{{translateService.currentLang}}/sign-up">{{'components.common.try-it-for-free' | translate}}</a>
              <span class="fw-semibold text-dark">{{'components.pricing-detail.plan-includes' | translate}}</span>
              <ul class="check-list mt-2 mb-0">
                <li class="arrow-left mb-3"><span
                  class="fw-semibold text-dark">{{'components.pricing-detail.all-the-features-of' | translate}} {{'components.pricing-detail.premium.title' | translate | lowercase}}{{','}} {{'components.pricing-detail.plus' | translate}}{{':'}}</span>
                </li>
                <li>
                  <span><strong>{{'components.pricing-detail.premium-plus.includes-1' | translate}}</strong></span>
                </li>
                <li><span>{{'components.pricing-detail.premium-plus.includes-2' | translate}}</span>
                </li>
                <li><span>{{'components.pricing-detail.premium-plus.includes-3' | translate}}</span>
                </li>
                <li><span>{{'components.pricing-detail.premium-plus.includes-4' | translate}}</span>
                </li>
                <li><span>{{'components.pricing-detail.premium-plus.includes-5' | translate}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
