// noinspection NonAsciiCharacters,JSNonASCIINames

import {ErrorHandler, NgModule, ɵɵinject} from '@angular/core';
import {CurrencyPipe, DOCUMENT, registerLocaleData, ViewportScroller} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import localeEsUs from '@angular/common/locales/es-US';
import localeEs from '@angular/common/locales/es';
import locale150 from '@angular/common/locales/en-150';
import locale001 from '@angular/common/locales/en-001';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from "@angular/forms";

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {NgxTypedJsModule} from "ngx-typed-js";
import {CookieService} from "ngx-cookie-service";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {PricingComponent} from './pricing/pricing.component';
import {ChooseCountryRegionComponent} from './choose-country-region/choose-country-region.component';
import {LocaleService} from "./services/locale.service";
import {
  ChooseCountryRegionWidgetComponent
} from './components/choose-country-region-widget/choose-country-region-widget.component';
import {IconsModule} from "./icons.module";
import {AboutUsComponent} from './about-us/about-us.component';
import {DistributionComponent} from './info/distribution/distribution.component';
import {TermsComponent} from './info/terms/terms.component';
import {PrivacyComponent} from './info/privacy/privacy.component';
import {LicenseComponent} from './info/license/license.component';
import {CookiesComponent} from './info/cookies/cookies.component';
import {OnlineBookingComponent} from './online-booking/online-booking.component';
import {AppointmentScheduleComponent} from './appointment-schedule/appointment-schedule.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {ClientDatabaseComponent} from './client-database/client-database.component';
import {FaqComponent} from './info/faq/faq.component';
import {PricingDetailComponent} from './pricing-detail/pricing-detail.component';
import {environment} from "../environments/environment";
import {CustomViewportScroller} from "./lib/custom-viewport-scroller";
import {RemoveThousandsSeparatorPipe} from "./lib/remove-thousands-separator.pipe";
import {AcceptLanguageInterceptor} from "./lib/accept-language.interceptor";
import {CustomCurrencyPipe} from "./lib/custom-currency.pipe";
import {MainLayoutComponent} from "./main-layout.component";
import {CustomTranslateLoader} from "./lib/custom-translate-loader";
import {AuthorizationInterceptor} from "./lib/authorization.interceptor";

export function createTranslateLoader(http: HttpClient) {
  return new CustomTranslateLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeMx);
registerLocaleData(localeEsUs);
registerLocaleData(localeEs);
registerLocaleData(locale150);
registerLocaleData(locale001);

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HomeComponent,
    PricingComponent,
    ChooseCountryRegionComponent,
    ChooseCountryRegionWidgetComponent,
    AboutUsComponent,
    DistributionComponent,
    TermsComponent,
    PrivacyComponent,
    LicenseComponent,
    CookiesComponent,
    OnlineBookingComponent,
    AppointmentScheduleComponent,
    NotificationsComponent,
    ClientDatabaseComponent,
    FaqComponent,
    PricingDetailComponent,
    /*NoLayoutComponent,
    CalendarComponent,
    WidgetComponent,
    MobileAppComponent,*/
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    IconsModule,
    TranslateModule.forRoot({
      // defaultLanguage: defaultLocale,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule, // after TranslateModule
    FormsModule,
    NgxTypedJsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gaMeasurementId, [
      {
        command: 'consent',
        values: [
          'default',
          {
            //  TODO set default per region (for example, for Europe or US)
            'ad_personalization': 'denied',
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
          }
        ]
      },
      {
        command: 'js', values: [new Date()]
      },
      {
        command: 'config',
        values: [environment.gaMeasurementId]
      },
    ]),
    NgxGoogleAnalyticsRouterModule,
    RemoveThousandsSeparatorPipe,
    CustomCurrencyPipe,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AcceptLanguageInterceptor, multi: true},
    HttpClient,
    LocaleService,
    CookieService,
    {
      provide: ViewportScroller,
      useFactory: () => new CustomViewportScroller('content-scroller', ɵɵinject(DOCUMENT), window, ɵɵinject(ErrorHandler))
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
