import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {retry} from "rxjs";

import {AbstractSubscriber} from "../services/abstract-subscriber";
import {environment} from "../../environments/environment";
import {countryCurrency} from "../locales";
import {PriceTier, PriceTiers} from "../pricing-detail/pricing-detail.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false
})
export class HomeComponent extends AbstractSubscriber {
  protected businesses: string[] = [];

  // for minimal price display
  protected individualPriceTier: { [currency: string]: PriceTier | undefined } = {};

  protected readonly environment = environment;
  protected readonly countryCurrency = countryCurrency;

  constructor(
    private readonly httpClient: HttpClient,
    protected translateService: TranslateService,
  ) {
    super();

    this.fillProperties();
    this.loadPriceTiers();

    this.track(
      this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        this.fillProperties();
      })
    );
  }

  protected getLocaleCurrencyCode(): string {
    return countryCurrency[this.translateService.currentLang.split('-')[1]];
  }

  private fillProperties() {
    this.businesses = this.translateService.instant('components.home.title-businesses');
  }

  private loadPriceTiers() {
    this.httpClient.get<PriceTiers>(environment.apiUrl + '/public/priceTiers').pipe(retry(1)).subscribe({
      next: (priceTiers: PriceTiers) => {
        this.individualPriceTier = priceTiers.individualPriceTier;
      },
    });
  }
}
