import {Component} from '@angular/core';
import {getLocaleCurrencyCode} from "@angular/common";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {AbstractSubscriber} from "../services/abstract-subscriber";
import {individualPriceTier} from "../price-tiers";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends AbstractSubscriber {
  protected businesses: string[] = [];

  // for minimal price display
  protected individualPriceTier = individualPriceTier;

  protected readonly environment = environment;

  constructor(
    protected translateService: TranslateService,
  ) {
    super();

    this.fillProperties();

    this.track(
      this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        this.fillProperties();
      })
    );
  }

  protected getLocaleCurrencyCode(): string {
    if (this.translateService.currentLang === 'en-150') {
      return 'EUR';
    }
    if (this.translateService.currentLang === 'en-001') {
      return 'USD';
    }
    return getLocaleCurrencyCode(this.translateService.currentLang) as string;
  }

  private fillProperties() {
    this.businesses = this.translateService.instant('components.home.title-businesses');
  }
}
