import {EventEmitter, Injectable} from '@angular/core';
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";

import {TranslateService} from "@ngx-translate/core";
import {retry} from "rxjs";

import {countryDefaultLocale, defaultLocale} from "../locales";
import {LOCALIZE_CACHE_NAME} from "../app-routing.module";

export const COUNTRY_CACHE_NAME = 'USER_COUNTRY';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public userCountryDetected = new EventEmitter();

  constructor(
    private http: HttpClient,
    private location: Location,
    public translateService: TranslateService,
  ) {
  }

  public getLocaleCountry(locale: string): string {
    return this.translateService.instant('countries.' + locale.split('-')[1]);
  }

  // return ISO 3166-2 two-character country code
  public async getUserCountry(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get('https://ip2c.org/self', {observe: 'body', responseType: 'text'}).pipe(retry(1)).subscribe({
        next: (data) => {
          const split = data.split(';');

          const country = split[1] ? split[1].toLowerCase() : 'xx';

          localStorage.setItem(COUNTRY_CACHE_NAME, country);

          resolve(country);
        }, error: (_) => {
          resolve('xx');
        }
      });
    });
  }

  // used only in detecting user country
  public getDefaultLocale(country: string): string {
    return countryDefaultLocale[country] || defaultLocale;
  }

  public changeLocale(locale: string, currentPath = '') {
    localStorage.setItem(LOCALIZE_CACHE_NAME, locale);
    this.translateService.use(locale).subscribe(() => {
      this.location.go(locale + currentPath, '', {chooseLocale: true});
      this.location.replaceState(locale + currentPath, '', {chooseLocale: true});
    });
  }

  public isUserUnderGDPR() {
    switch (localStorage.getItem(COUNTRY_CACHE_NAME)) {
      case 'at': // Austria
      case 'be': // Belgium
      case 'bg': // Bulgaria
      case 'hr': // Croatia
      case 'cy': // Cyprus
      case 'cz': // Czechia (Czech Republic)
      case 'dk': // Denmark
      case 'ee': // Estonia
      case 'fi': // Finland
      case 'fr': // France
      case 'de': // Germany
      case 'gr': // Greece
      case 'hu': // Hungary
      case 'ie': // Ireland
      case 'it': // Italy
      case 'lv': // Latvia
      case 'lt': // Lithuania
      case 'lu': // Luxembourg
      case 'mt': // Malta
      case 'nl': // Netherlands, Kingdom of the
      case 'pl': // Poland
      case 'pt': // Portugal
      case 'ro': // Romania
      case 'sk': // Slovakia
      case 'si': // Slovenia
      case 'es': // Spain
      case 'se': // Sweden
      case 'gb': // United Kingdom of Great Britain and Northern Ireland
      case 'xe': // Europe generic code from http://ip2c.org/
        return true;
      default:
        return false;
    }
  }

  public isUserFromItaly() {
    return localStorage.getItem(COUNTRY_CACHE_NAME) === 'it';
  }

  public isUserFromDenmarkOrGreece() {
    return localStorage.getItem(COUNTRY_CACHE_NAME) === 'dk' || localStorage.getItem(COUNTRY_CACHE_NAME) === 'gr';
  }

  public isUserFromUSA() {
    return localStorage.getItem(COUNTRY_CACHE_NAME) === 'us';
  }

  public isUserFromMexico() {
    return localStorage.getItem(COUNTRY_CACHE_NAME) === 'mx';
  }

  public cookieConsentNeeded() {
    // not needed for Mexico
    return this.isUserUnderGDPR();
  }

  public cookieBannerNeeded() {
    return this.isUserUnderGDPR() || this.isUserFromMexico();
  }

  public privacyBannerNeeded() {
    return this.isUserFromUSA();
  }
}
