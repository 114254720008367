import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivationEnd, Router, UrlSegment} from "@angular/router";

import {LocaleService} from "../../services/locale.service";
import {locales} from "../../locales";
import {AbstractSubscriber} from "../../services/abstract-subscriber";

@Component({
  selector: 'app-choose-country-region-widget',
  templateUrl: './choose-country-region-widget.component.html',
  styleUrls: ['./choose-country-region-widget.component.scss'],
  standalone: false
})
export class ChooseCountryRegionWidgetComponent extends AbstractSubscriber {
  @Input() linkClass = '';

  @Output() localeSelect = new EventEmitter();

  public currentPath = '';
  protected readonly locales = locales;

  constructor(
    public localeService: LocaleService,
    private router: Router,
  ) {
    super();

    this.track(
      this.router.events.subscribe((event) => {
        if (event instanceof ActivationEnd) {
          if (event.snapshot.data['localizeRouter'] && (event.snapshot.url.length || event.snapshot.routeConfig?.title === 'page-titles.home')) {
            this.currentPath = '/' + event.snapshot.url.map((urlSegment: UrlSegment) => urlSegment.path).join('/');
          }
        }
      })
    );
  }
}
