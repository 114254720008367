import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {from, lastValueFrom, Observable} from 'rxjs';

import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('assets/i18n') || request.url.includes('ip2c.org')) { // don't intercept requests from @ngx-translate and to ip2c.org
      return next.handle(request);
    }

    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const accessToken = await this.authService.getAccessToken();

    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
    }

    return lastValueFrom(next.handle(request));
  }
}
