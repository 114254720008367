import {Injectable} from '@angular/core';

import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from "amazon-cognito-identity-js";

import {environment} from "../../environments/environment";

const ACCESS_TOKEN_CACHE_NAME = 'ACCESS_TOKEN';
const ACCESS_TOKEN_EXP_CACHE_NAME = 'ACCESS_TOKEN_EXP';
const REFRESH_TOKEN_CACHE_NAME = 'REFRESH_TOKEN';
const REFRESH_TOKEN_EXP_CACHE_NAME = 'REFRESH_TOKEN_EXP'; // expires in 5 days - configure in AWS Cognito
const fiveDaysInSeconds = 5 * 24 * 60 * 60;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly cognitoUser: CognitoUser;
  private readonly authenticationDetails: AuthenticationDetails;

  constructor() {
    this.authenticationDetails = new AuthenticationDetails({
      Username: environment.cognito.email,
      Password: environment.cognito.password,
    });

    let userPool = new CognitoUserPool({
      UserPoolId: environment.cognito.userPoolId,
      ClientId: environment.cognito.userPoolWebClientId,
    });

    this.cognitoUser = new CognitoUser({Username: environment.cognito.email, Pool: userPool});
  }

  public async getAccessToken(): Promise<string> {
    const localStorageAccessToken = localStorage.getItem(ACCESS_TOKEN_CACHE_NAME);
    const localStorageAccessTokenExpiration = parseInt(localStorage.getItem(ACCESS_TOKEN_EXP_CACHE_NAME) as string);
    if (localStorageAccessToken && !this.isTokenExpired(localStorageAccessTokenExpiration)) {
      return Promise.resolve(localStorageAccessToken as string);
    }

    const localStorageRefreshToken = localStorage.getItem(REFRESH_TOKEN_CACHE_NAME) as string;
    const localStorageRefreshTokenExpiration = parseInt(localStorage.getItem(REFRESH_TOKEN_EXP_CACHE_NAME) as string);

    if (localStorageRefreshToken && !this.isTokenExpired(localStorageRefreshTokenExpiration)) {
      try {
        await this.refreshSession(new CognitoRefreshToken({RefreshToken: localStorageRefreshToken}));
      } catch (_) {
        await this.authenticateUser();
      }
    } else {
      await this.authenticateUser();
    }

    return Promise.resolve(localStorage.getItem(ACCESS_TOKEN_CACHE_NAME) as string);
  }

  private isTokenExpired(tokenExpiration: number) {
    return Math.ceil(Date.now() / 1000) - tokenExpiration > 0;
  }

  private async authenticateUser() {
    return new Promise<void>((resolve, reject) => {
      this.cognitoUser.authenticateUser(this.authenticationDetails, {
        onSuccess: (result: CognitoUserSession) => {
          this.processCognitoUserSession(result);

          resolve();
        },
        onFailure: (error: any) => {
          console.log("error", error);

          reject();
        },
      });
    });
  }

  private async refreshSession(refreshToken: CognitoRefreshToken) {
    return new Promise<void>((resolve, reject) => {
      this.cognitoUser.refreshSession(refreshToken, (err, result: CognitoUserSession) => {
        if (err) {
          console.log('refreshSession err', err);

          reject();
        }

        if (result) {
          this.processCognitoUserSession(result);

          resolve();
        }
      });
    });
  }

  private processCognitoUserSession(result: CognitoUserSession) {
    const accessToken = result.getIdToken();

    localStorage.setItem(ACCESS_TOKEN_CACHE_NAME, accessToken.getJwtToken());
    localStorage.setItem(ACCESS_TOKEN_EXP_CACHE_NAME, accessToken.getExpiration().toString());

    const refreshToken = result.getRefreshToken();

    localStorage.setItem(REFRESH_TOKEN_CACHE_NAME, refreshToken.getToken());
    localStorage.setItem(REFRESH_TOKEN_EXP_CACHE_NAME, Math.ceil(Date.now() / 1000 + fiveDaysInSeconds).toString());
  }
}
