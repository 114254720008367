import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  standalone: false
})
export class PrivacyComponent {
  constructor(
    protected translateService: TranslateService,
  ) {
  }

}
