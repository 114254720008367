import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {getLocaleCurrencyCode} from "@angular/common";
import {Title} from "@angular/platform-browser";

import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

import {LOCALE_EN_001, LOCALE_EN_150, LOCALE_ES_ES, LOCALE_ES_MX, LOCALE_ES_US} from "../locales";
import {AbstractSubscriber} from "../services/abstract-subscriber";
import {
  individualPriceTier,
  premiumPlusPriceTier,
  premiumPriceTier,
  PriceTier,
  standardPriceTier
} from "../price-tiers";

import {environment} from "../../environments/environment";

const PRICING_MODE_MONTHLY = 'monthly';
const PRICING_MODE_YEARLY = 'yearly';

@Component({
  selector: 'app-pricing-detail',
  templateUrl: './pricing-detail.component.html',
  styleUrls: ['./pricing-detail.component.scss']
})
export class PricingDetailComponent extends AbstractSubscriber implements OnInit {
  protected readonly Array = Array;

  protected businessTypeId = 9;
  protected businessTypeName = '';

  protected pricingMode: typeof PRICING_MODE_MONTHLY | typeof PRICING_MODE_YEARLY = PRICING_MODE_MONTHLY;

  protected readonly PRICING_MODE_MONTHLY = PRICING_MODE_MONTHLY;
  protected readonly PRICING_MODE_YEARLY = PRICING_MODE_YEARLY;
  protected readonly Infinity = Infinity;

  protected yearlyDiscount = 2; // months

  protected individualPriceTier = individualPriceTier;
  protected standardPriceTier = standardPriceTier;
  protected premiumPriceTier = premiumPriceTier;
  protected premiumPlusPriceTier = premiumPlusPriceTier;

  protected standardWhatsApp = true;
  protected premiumWhatsApp = true;
  protected premiumSMS = true;

  protected standardPriceTierProfessionalsCount = 0;
  protected premiumPriceTierProfessionalsCount = 0;
  protected premiumPlusPriceTierProfessionalsCount = 0;

  protected adjectiveFirst = true;

  protected readonly environment = environment;

  constructor(
    private route: ActivatedRoute,
    private readonly title: Title,
    protected translateService: TranslateService,
  ) {
    super();

    this.fillPriceValues();

    this.track(
      this.translateService.onLangChange.subscribe((_: LangChangeEvent) => {
        this.fillPriceValues();
      })
    );
  }

  ngOnInit() {
    this.track(
      this.route.params.subscribe((params) => {
        this.businessTypeId = +params['businessTypeId']; // (+) converts string 'id' to a number

        this.fillPriceValues();
      })
    );
  }

  protected onMonthlyClick() {
    this.pricingMode = PRICING_MODE_MONTHLY;
  }

  protected onYearlyClick() {
    this.pricingMode = PRICING_MODE_YEARLY;
  }

  protected getLocaleCurrencyCode(): string {
    if (this.translateService.currentLang === 'en-150') {
      return 'EUR';
    }
    if (this.translateService.currentLang === 'en-001') {
      return 'USD';
    }
    return getLocaleCurrencyCode(this.translateService.currentLang) as string;
  }

  protected annualMultiplier() {
    return this.pricingMode === PRICING_MODE_YEARLY ? 12 - this.yearlyDiscount : 1;
  }

  protected getPriceTierValue(priceTier: PriceTier, professionalsCount: number, whatsapp = false, sms = false): number {
    if (professionalsCount <= priceTier.minProfessionals) {
      const whatsAppAddition = whatsapp ? (priceTier.whatsapp * priceTier.minProfessionals) : 0;
      const smsAddition = sms ? ((priceTier.sms || 0) * priceTier.minProfessionals) : 0;
      return priceTier.base + whatsAppAddition + smsAddition;
    }

    if (professionalsCount === Infinity) {
      const whatsAppAddition = whatsapp ? (priceTier.whatsappUnlimited || 0) : 0;
      const smsAddition = sms ? (priceTier.smsUnlimited || 0) : 0;
      return priceTier.unlimited as number + whatsAppAddition + smsAddition;
    }

    const whatsAppAddition = whatsapp ? (priceTier.whatsapp * professionalsCount) : 0;
    const smsAddition = sms ? ((priceTier.sms || 0) * professionalsCount) : 0;
    return priceTier.base + (professionalsCount - priceTier.minProfessionals) * priceTier.increment + whatsAppAddition + smsAddition;
  }

  private fillPriceValues() {
    if (this.businessTypeId !== 9) {
      this.businessTypeName = this.translateService.instant(`components.pricing.business-type-${this.businessTypeId}.title`);

      this.title.setTitle(this.translateService.instant('menu-items.pricing') + ' - ' + this.businessTypeName + ' - Agendacita');
    } else {
      this.businessTypeName = 'Other';
    }

    this.standardPriceTierProfessionalsCount = 2;
    this.premiumPriceTierProfessionalsCount = 5;
    this.premiumPlusPriceTierProfessionalsCount = 15;

    this.standardWhatsApp = true;
    this.premiumWhatsApp = true;
    this.premiumSMS = false;

    if (
      this.translateService.currentLang === LOCALE_ES_ES ||
      this.translateService.currentLang === LOCALE_EN_150 ||
      this.translateService.currentLang === LOCALE_EN_001
    ) {
      this.standardWhatsApp = false;
      this.premiumWhatsApp = false;
      this.premiumSMS = false;
    }

    this.adjectiveFirst = (this.translateService.currentLang !== LOCALE_ES_MX && this.translateService.currentLang !== LOCALE_ES_US && this.translateService.currentLang !== LOCALE_ES_ES) || this.businessTypeId === 9;
  }
}
